<template>
  <div class="w-full md:w-card-width">
    <!-- Buttons -->
    <ul
      class="
        mt-8
        hidden
        md:flex
        flex-col
        md:flex-row
        space-y-2
        md:space-y-0
        space-x-0
        md:space-x-4
      "
    >
      <Tab
        v-for="categroyTitle in Object.keys(category)"
        :key="categroyTitle"
        :highlightedCategory="currentTab"
        :category="categroyTitle"
        @tabsToggled="
          currentTab = $event;
          setCategoryObject();
        "
      >
      </Tab>
    </ul>

    <!-- Drop down select -->
    <div class="mt-4 w-full relative inline-flex md:hidden">
      <svg
        class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 412 232"
      >
        <path
          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
          fill="#4B5563"
          fill-rule="nonzero"
        />
      </svg>
      <select
        v-model="currentTab"
        @change="setCategoryObject()"
        class="
          pl-6
          appearance-none
          w-full
          h-10
          rounded-sm
          nm-flat-gray-200
          hover:nm-flat-gray-200-lg
          focus:outline-none
          transition
          duration-100
          ease-in-out
        "
      >
        <option
          v-for="categroyTitle in Object.keys(category)"
          :key="categroyTitle"
        >
          {{ categroyTitle }}
        </option>
      </select>
    </div>

    <!-- Attributes Card -->
    <div
      class="
        mt-4
        md:mt-8
        p-2
        flex
        justify-center
        nm-flat-gray-200
        w-full
        h-card-height-mobile
        md:h-80
      "
    >
      <div class="flex flex-col items-start w-full h-full">
        <!-- Gradient Header -->
        <div
          class="
            px-2
            w-full
            bg-gradient-to-r
            from-green-600
            to-yellow-300
            text-white
          "
        >
          Concept Prompt: \{{ currentTab }}
        </div>
        <!-- Attributes Grid-->
        <div class="p-6 sm:p-4 nm-inset-gray-200 w-full h-full bg-gray-200">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-y-4">
            <div
              class="
                md:flex
                md:space-x-1
                whitespace-pre
                leading-none
                md:leading-normal
              "
              v-for="(attribute, name) in category[currentTab]"
              :key="name"
            >
              <!-- Mobile Attributes View -->
              <div class="md:hidden">
                <div>{{ name }}:</div>
                <div class="flex opacity-70 items-center justify-between">
                  <div class="">{{ attribute }}</div>
                  <button
                    class="sm:ml-1 ml-0 text-gray-400 focus:outline-none"
                    @click="generateRandomAttributeFromName(name)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <!-- Default Attributes View -->
              <div class="md:flex items-center space-x-1 hidden">
                <button
                  class="md:mr-1 ml-0 text-gray-400 focus:outline-none"
                  @click="generateRandomAttributeFromName(name)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 3.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                  </svg>
                </button>
                <div class="mr-1">{{ name }}:</div>
                <div class="opacity-70">{{ attribute }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Generate Button -->
    <div class="flex justify-center items-center">
      <button
        class="
          mt-4
          md:mt-8
          p-2
          w-72
          h-14
          rounded-sm
          nm-flat-gray-200
          opacity-100
          lg:opacity-80
          hover:opacity-80
          hover:nm-flat-gray-200-lg
          focus:opacity-100
          focus:outline-none
          transition
          duration-100
          ease-in-out
        "
        v-on:click="setAttributes"
      >
        <div
          class="
            flex
            items-center
            justify-center
            w-full
            h-full
            bg-green-600
            text-white text-center
            opacity-100
            hover:opacity-100
            lg:hover:opacity-80
          "
        >
          Generate
        </div>
      </button>
    </div>

    <!-- Misc buttons -->
    <div class="flex flex-row justify-center mt-4 space-x-4">
      <!-- Buy me a coffee button -->
      <div class="flex justify-center items-center">
        <a
          class="
            p-2
            sm:w-40
            h-10
            rounded-sm
            nm-flat-yellow-300
            opacity-100
            lg:opacity-80
            hover:opacity-80
            hover:nm-flat-yellow-200-lg
            focus:opacity-100
            focus:outline-none
            transition
            duration-100
            ease-in-out
          "
          href="https://www.buymeacoffee.com/CommandV"
          target="_blank"
        >
          <div class="flex items-center justify-center w-full h-full">
            <img class="h-8" src="../assets/buy-me-a-coffee-logo-mark-1.svg" />
            <div class="text-black mr-1">Buy us a coffee</div>
          </div>
        </a>
      </div>

      <!-- YouTube Button -->
      <div class="flex justify-center items-center opacity-80 group">
        <a
          class="
            p-2
            h-10
            rounded-sm
            nm-flat-gray-200
            opacity-100
            lg:opacity-80
            hover:opacity-80
            hover:nm-flat-gray-200-lg
            focus:opacity-100
            focus:outline-none
            transition
            duration-100
            ease-in-out
          "
          href="https://www.youtube.com/watch?v=vOjEpUQOcJc"
          target="_blank"
        >
          <div class="flex items-center justify-center w-full h-full">
            <svg
              class="
                w-10
                h-16
                group-hover:opacity-100
                fill-current
                text-gray-600
                focus-visible:text-gray-600
                group-hover:text-red-600
              "
              xmlns="http://www.w3.org/2000/svg"
              height="800"
              width="1200"
              viewBox="-35.20005 -41.33325 305.0671 247.9995"
            >
              <path
                d="M93.333 117.559V47.775l61.334 34.893zm136.43-91.742c-2.699-10.162-10.651-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.555 7.652 7.603 15.655 4.904 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.904 56.849c2.699 10.163 10.65 18.165 20.747 20.883 18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.683-4.934c10.096-2.718 18.048-10.72 20.747-20.883 4.904-18.42 4.904-56.85 4.904-56.85s0-38.43-4.904-56.849"
              />
            </svg>

            <div class="mr-1">YouTube</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import Tab from "./Tab.vue";
import data from "../assets/data.json";

export default {
  name: "InteractiveElements",
  data() {
    return {
      currentTab: "Character",
      currentCategoryObject: {},
      currentCategoryData: {},
      currentSubcategories: [],
      category: {
        Character: {
          Species: " ",
          Age: " ",
          Role: " ",
          Appearance: " ",
          Persona: " ",
          "Key Feature": " ",
          Accessory: " ",
          "Design Inspiration": " ",
        },
        Creature: {
          "Species Influence": " ",
          Modifiers: " ",
          Habitat: " ",
          Demeanor: " ",
          Size: " ",
        },
        Environment: {
          Setting: " ",
          Location: " ",
          Weather: " ",
          "Hero Element": " ",
          "Design Inspiration": " ",
          "Time of Day": " ",
          Feel: " ",
        },
        Prop: {
          Kind: " ",
          "Design Style": " ",
          "Material Accent": " ",
          Attribute: " ",
        },
        Vehicle: {
          Type: " ",
          Weight: " ",
          Task: " ",
          "Power Source": " ",
          "Design Style": " ",
        },
      },
    };
  },
  beforeMount() {
    this.setCategoryObject();
  },
  methods: {
    setCategoryObject() {
      switch (this.currentTab) {
        case "Character":
          this.currentCategoryObject = this.category.Character;
          this.currentCategoryData = data.Character;
          this.currentSubcategories = Object.keys(this.category.Character);
          break;
        case "Creature":
          this.currentCategoryObject = this.category.Creature;
          this.currentCategoryData = data.Creature;
          this.currentSubcategories = Object.keys(this.category.Creature);
          break;
        case "Environment":
          this.currentCategoryObject = this.category.Environment;
          this.currentCategoryData = data.Environment;
          this.currentSubcategories = Object.keys(this.category.Environment);
          break;
        case "Prop":
          this.currentCategoryObject = this.category.Prop;
          this.currentCategoryData = data.Prop;
          this.currentSubcategories = Object.keys(this.category.Prop);
          break;
        case "Vehicle":
          this.currentCategoryObject = this.category.Vehicle;
          this.currentCategoryData = data.Vehicle;
          this.currentSubcategories = Object.keys(this.category.Vehicle);
          break;
      }
    },
    getRandomIndex(max) {
      return Math.floor(Math.random() * max);
    },
    getRandomSpecies() {
      const randomNumber = this.getRandomIndex(10);
      const randomSpeciesIndex = this.getRandomIndex(data.Species.length);
      const randomAnimalIndex = this.getRandomIndex(data.Animals.length);
      const randomSpecies = data.Species[randomSpeciesIndex];
      const randomAnimal = data.Animals[randomAnimalIndex];

      // 30% chance to get an animal hybrid
      if (randomNumber <= 3) {
        return `${randomSpecies} (${randomAnimal})`;
      }

      return randomSpecies;
    },
    getRandomAttribute(category) {
      const randomCategoryIndex = this.getRandomIndex(category.length);
      const randomCategory = category[randomCategoryIndex];
      const randomAttributeIndex = this.getRandomIndex(
        data[randomCategory].length
      );
      return data[randomCategory][randomAttributeIndex];
    },
    setAttributes() {
      this.currentSubcategories.forEach((attribute) => {
        if (attribute == "Species") {
          const characterCategory = this.category.Character;
          characterCategory["Species"] = this.getRandomSpecies();
          return;
        }
        this.generateRandomAttribute(
          this.currentCategoryObject,
          attribute,
          this.currentCategoryData[attribute]
        );
      });
    },
    generateRandomAttribute(categoryObject, categoryName, dataCategory) {
      categoryObject[categoryName] = this.getRandomAttribute(dataCategory);
    },
    generateRandomAttributeFromName(name) {
      const characterCategory = this.category.Character;
      if (name == "Species") {
        characterCategory["Species"] = this.getRandomSpecies();
        return;
      }
      this.generateRandomAttribute(
        this.currentCategoryObject,
        name,
        this.currentCategoryData[name]
      );
    },
  },
  components: {
    Tab,
  },
};
</script>

