<template >
  <!-- TODO: change to space between instead of margin right -->
  <li class="flex-auto text-center">
    <button
      class="
        w-36
        h-10
        rounded-sm
        nm-flat-gray-200
        opacity-50
        hover:opacity-80
        hover:nm-flat-gray-200-lg
        focus:opacity-100
        focus:outline-none
        transition
        duration-100
        ease-in-out
      "
      @click="toggleTabs"
      :class="{
        'opacity-50': highlightedCategory !== category,
        'opacity-100': highlightedCategory === category,
      }"
    >
      {{ category }}
    </button>
  </li>
</template>

<script>
export default {
  name: "Tab",
  props: ["highlightedCategory", "category"],
  data: () => {
    return {
      storedCategory: "",
    };
  },
  methods: {
    toggleTabs() {
      this.storedCategory = this.category;
      this.$emit("tabsToggled", this.storedCategory);
    },
  },
};
</script>
