<template>
  <div class="h-screen flex flex-col">
    <main class="px-5 flex-grow flex justify-center items-center">
      <div
        class="flex flex-col text-lg text-gray-600 items-center divide-y-2 divide-gray-400 divide-dashed"
      >
        <div
          class="flex flex-row md:flex-col space-x-2 sm:space-x-4 justify-center items-center"
        >
          <img
            alt="CommandV Logo"
            src="./assets/logo.png"
            class="my-3 md:mt-2 w-12 sm:w-16 md:w-20"
          />
          <img
            alt="Conceptinator"
            src="./assets/conceptinator.png"
            class="mt-3 md:my-2 w-3/4"
          />
        </div>
        <InteractiveElements> </InteractiveElements>
      </div>
    </main>
    <footer
      class="flex flex-col justify-center items-center pb-4 pt-10 text-gray-600 opacity-80"
    >
      <div>
        Share your art with us:
        <a
          class="underline hover:text-black"
          href="https://www.instagram.com/explore/tags/conceptinator/"
          target="_blank"
          >#Conceptinator</a
        >
      </div>
      <div class="">
        © {{ new Date().getFullYear() }},
        <a
          class="underline hover:text-black"
          href="https://www.youtube.com/channel/UC9VuGyOVZ9XIN7o3IRGK6bA"
          target="_blank"
        >
          CommandV
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import InteractiveElements from "./components/InteractiveElements.vue";

export default {
  name: "App",
  components: {
    InteractiveElements,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
#app {
  font-family: VT323, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  background-color: #e5e7eb;
  touch-action: manipulation;
  font-size: 90%;
}
@media screen and (max-width: 600px) {
  html {
    font-size: 100%;
  }
}
</style>
